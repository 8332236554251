<template>
  <v-alert
    :name="name"
    :value="$store.getters['get_global_errorStatus']"
    type="error"
    prominent
    dismissible
    elevation="3"
    transition="fade-transition"
    origin="center center"
    class="mt-4 mb-0"
    v-if="$store.getters['get_global_error']"
    @input="
      $store.commit('global_clearError');
      $store.commit('validator/clear_ValidatorErrors');
    "
  >
    <template
      v-if="
        Array.isArray($store.getters['get_global_error'].message) &&
          $store.getters['get_global_error'].message.length > 0
      "
    >
      <ul v-for="er in arrayValues" :key="er" style="list-style-type: none">
        <li class="my-3">{{ er }}</li>
      </ul>
    </template>
    <span v-else>{{ $t($store.getters["get_global_error"].message) }}</span>
  </v-alert>
</template>
<script>
import { stringTemplateParser } from "../helpers/helpers";
import { mapGetters } from "vuex";

export default {
  name: "AlertError",
  props: ["text", "name", "error"],
  data() {
    return {
      labels: []
    };
  },
  computed: {
    arrayValues() {
      if (
        this.get_global_error === null ||
        !Array.isArray(this.get_global_error.message)
      ) {
        return [];
      } else {
        const errors = this.get_global_error.message;
        // eslint-disable-next-line no-unused-vars
        const map = {
          requestTypeRequired: 0,
          companyDetails: 1,
          facilityDetails: 2,
          facilityMinimum: 3,
          hatcheryDetails: 4,
          preraiserDetails: 5,
          preraiserStablesMinimum: 6,
          rearerDetails: 7,
          rearerStablesMinimum: 8,
          layingFarmDetails: 9,
          layingFarmStablesMinimum: 10,
          dyehouseDetails: 11,
          packingDetails: 12,
          feedMillsDetails: 13,
          homeCompoundersDetails: 14,
          dealersDetails: 15,
          eggFoodFacilityDetails: 16
        };
        const t = this;
        errors.sort(function(a, b) {
          return t.getIndex(a) - t.getIndex(b);
        });
        if (errors.find(a => a.includes("{{"))) {
          let res = [];
          errors.forEach(err =>
            res.push(
              stringTemplateParser(err, {
                requestTypeRequired: this.$t(
                  "validationMessages.requestTypeRequired"
                ),
                companyDetails: this.$t("validationMessages.companyDetails"),
                facilityDetails: this.$t("validationMessages.facilityDetails"),
                facilityMinimum: this.$t("validationMessages.facilityMinimum"),
                hatcheryDetails: this.$t("validationMessages.hatcheryDetails"),
                preraiserDetails: this.$t(
                  "validationMessages.preraiserDetails"
                ),
                preraiserStablesMinimum: this.$t(
                  "validationMessages.preraiserStablesMinimum"
                ),
                rearerDetails: this.$t("validationMessages.rearerDetails"),
                rearerStablesMinimum: this.$t(
                  "validationMessages.rearerStablesMinimum"
                ),
                layingFarmDetails: this.$t(
                  "validationMessages.layingFarmDetails"
                ),
                layingFarmStablesMinimum: this.$t(
                  "validationMessages.layingFarmStablesMinimum"
                ),
                dyehouseDetails: this.$t("validationMessages.dyehouseDetails"),
                packingDetails: this.$t("validationMessages.packingDetails"),
                feedMillsDetails: this.$t(
                  "validationMessages.feedMillsDetails"
                ),
                homeCompoundersDetails: this.$t(
                  "validationMessages.homeCompoundersDetails"
                ),
                dealersDetails: this.$t("validationMessages.dealersDetails"),
                eggFoodFacilityDetails: this.$t(
                  "validationMessages.eggFoodFacilityDetails"
                )
              })
            )
          );
          return res;
        } else return errors;
      }
    },
    terrors() {
      Object.keys(this.$parent.errorMessages).forEach(key =>
        this.getLabels(this.$parent.schemaFieldsToValidate, key)
      );
      return this.labels;
    },
    ...mapGetters(["get_global_error"])
  },
  methods: {
    getLabels(obj, itm) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && key === itm) {
          //fallback for radios with inputHeading instead of label
          if (
            !obj[key].hasOwnProperty("label") &&
            obj[key].fieldType === "RadioInput"
          ) {
            this.labels.push(obj[key].inputHeading);
          } else this.labels.push(obj[key].label);
        }
      }
    },
    getIndex(x) {
      if (x.includes("requestTypeRequired")) {
        return "0";
      } else if (x.includes("companyDetails")) {
        return "1";
      } else if (x.includes("facilityDetails")) {
        return "2";
      } else if (x.includes("facilityMinimum")) {
        return "3";
      } else if (x.includes("hatcheryDetails")) {
        return "4";
      } else if (x.includes("preraiserDetails")) {
        return "5";
      } else if (x.includes("preraiserStablesMinimum")) {
        return "6";
      } else if (x.includes("rearerDetails")) {
        return "7";
      } else if (x.includes("rearerStablesMinimum")) {
        return "8";
      } else if (x.includes("layingFarmDetails")) {
        return "9";
      } else if (x.includes("layingFarmStablesMinimum")) {
        return "10";
      } else if (x.includes("dyehouseDetails")) {
        return "11";
      } else if (x.includes("packingDetails")) {
        return "12";
      } else if (x.includes("feedMillsDetails")) {
        return "13";
      } else if (x.includes("homeCompoundersDetails")) {
        return "14";
      } else if (x.includes("dealersDetails")) {
        return "15";
      } else if (x.includes("eggFoodFacilityDetails")) {
        return "16";
      } else return "100";
    }
  }
};
</script>
<style scoped></style>
